<template>
    <div class="content pt-32">
        <div class="container mx-auto flex flex-col-reverse lg:flex-row px-6 pb-12">
            <div class="w-full lg:w-1/2 text-gray-700">
                <div class="lg:pr-6">
                    <h1 class="text-3xl xl:text-4xl font font-heading mb-2 text-gray-700 font-bold">Portfolio<span class="font-mono text-red-600">.</span></h1>

                    <div class="flex flex-col font-body text-gray-700 mb-5">
                        <span class="text-xl font-semibold">
                            Jonathan Russell Briggs
                            <span class="text-sm font-light">BA (Hons)</span>
                        </span>
                        <span class="text-sm font-condensed">
                            Graphic Designer
                            <span class="text-red-600 text-md font-bold">|</span>
                            Branding Consultant
                        </span>
                    </div>

                    <p class="font-condensed leading-relaxed mb-4 text-lg">This portfolio showcases a range of the eye-catching, visual communication assignments I have completed during the last three years, both for individual clients and corporate entities.</p>

                    <p class="font-body mb-4 font-light leading-relaxed">It also includes several examples of the logos, <router-link to="/branding" class="underline">branding</router-link> and product <router-link to="/design" class="underline">designs</router-link> I have created, often in accordance with a tightly controlled brief, and demonstrates my versatility / expertise with exhibition design, stationery, infographics, and a range of different <router-link to="/printing" class="underline">print</router-link> media.</p>
                </div>
            </div>
            <div class="w-full relative lg:w-1/2 flex justify-center lg:justify-start pl-8">
                <div class="xl:absolute xl:-top-16 flex justify-center">
                    <img src="@/assets/img/jon.png" class="w-full h-full sm:w-1/2 lg:w-full">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'content-single',
        components: {

        }
    }
</script>