<template>
	<div id="body" class="w-full h-screen" style="background-image: url('/assets/img/question.png">
		<Header/>
		<main>
			<Content/>
            <Gallery type="portfolio"/>
		</main>
        <Footer height="short"/>
	</div>
</template>

<script>
	import Header from '@/components/Header'
    import Content from '@/components/content/ContentPortfolio'
    import Gallery from '@/components/content/gallery/GalleryPortfolio'
	import Footer from '@/components/Footer'

	export default {
        name: 'Portfolio',
		components: {
            Header,
            Content,
            Gallery,
			Footer,
		},
	}
</script>
