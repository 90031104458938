<template>
    <div :class="'gallery-' + type" class="gallery flex bg-gray-900 bg-opacity-90 px-6 py-12" > 
        <div class="container mx-auto">
            <CoolLightBox 
                :items="items" 
                :index="index"
                @close="index = null">
            </CoolLightBox>

            <div class="images-wrapper w-full">
                <masonry
                    :cols="{default: 3, 700: 2, 480: 1}"
                    :gutter="20"
                >
                    <div
                        class="image cursor-pointer"
                        v-for="(image, imageIndex) in items"
                        :key="imageIndex"
                        @click="index = imageIndex"
                    >
                        <img :src="image">
                    </div>
                </masonry>
            </div>
        </div>
    </div>
</template>

<script>
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: 'GalleryPortfolio',
        props: {
            type: {
                type: String,
            }
        },
        data () {
            return {
                items: [],
                index: null,
            }
        },
        components: {
            CoolLightBox,
        },
        mounted () {
            this.import(require.context('@/assets/img/portfolio/', true, /\.jpg/))
        },
        methods: {
            import(r) {
                r.keys().forEach(key => (
                    this.items.push(r(key))
                ))
            },
        },
    }
</script>